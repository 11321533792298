import axios from '@/common/axios'
import qs from 'qs'

export function addClothesDisinfection (data) {
  return axios({
    method: 'post',
    url: '/production/clothesDisinfection/add',
    data: qs.stringify(data)
  })
}

export function deleteClothesDisinfection (id) {
  return axios({
    method: 'delete',
    url: '/production/clothesDisinfection/delete/' + id
  })
}

export function updateClothesDisinfection (data) {
  return axios({
    method: 'put',
    url: '/production/clothesDisinfection/update',
    data: qs.stringify(data)
  })
}

export function selectClothesDisinfectionInfo (id) {
  return axios({
    method: 'get',
    url: '/production/clothesDisinfection/info/' + id
  })
}

export function selectClothesDisinfectionList (query) {
  return axios({
    method: 'get',
    url: '/production/clothesDisinfection/list',
    params: query
  })
}

export function reviewClothesDisinfection (data) {
  return axios({
    method: 'put',
    url: '/production/clothesDisinfection/updateReview',
    data: qs.stringify(data)
  })
}
