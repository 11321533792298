<template>
  <div id="clothesDisinfection">
    <el-dialog
      :title="clothesDisinfectionFormTitle"
      width="1200px"
      :visible.sync="clothesDisinfectionDialogVisible"
      :close-on-click-modal="false"
      @close="clothesDisinfectionDialogClose"
    >
      <el-form
        ref="clothesDisinfectionFormRef"
        :model="clothesDisinfectionForm"
        :rules="clothesDisinfectionFormRules"
        label-position="right"
        label-width="120px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="清洗设备编号" prop="cleaningDeviceNo">
              <el-select
                v-model="clothesDisinfectionForm.cleaningDeviceNo"
                placeholder="请选择清洗设备"
                clearable
                :disabled="clothesDisinfectionFormTitle !== '新增工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '修改工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '工作服、鞋清洗消毒记录详情'"
                @change="devChange1"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.deviceNo"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="清洗设备名称" prop="cleaningDeviceName">
              <el-input
                v-model="clothesDisinfectionForm.cleaningDeviceName"
                placeholder="请输入清洗设备名称"
                clearable
                :disabled="clothesDisinfectionFormTitle !== '新增工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '修改工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '工作服、鞋清洗消毒记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="消毒设备编号" prop="disinfectionDeviceNo">
              <el-select
                v-model="clothesDisinfectionForm.disinfectionDeviceNo"
                placeholder="请选择消毒设备"
                clearable
                :disabled="clothesDisinfectionFormTitle !== '新增工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '修改工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '工作服、鞋清洗消毒记录详情'"
                @change="devChange2"
              >
                <el-option
                  v-for="item in devList"
                  :key="item.id"
                  :label="item.deviceNo"
                  :value="item.deviceNo"
                >
                  <span style="float: left">{{ item.deviceNo }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deviceName }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="消毒设备名称" prop="disinfectionDeviceName">
              <el-input
                v-model="clothesDisinfectionForm.disinfectionDeviceName"
                placeholder="请输入消毒设备名称"
                clearable
                :disabled="clothesDisinfectionFormTitle !== '新增工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '修改工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '工作服、鞋清洗消毒记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="清洗消毒日期" prop="disinfectionDate">
              <el-date-picker
                v-model="clothesDisinfectionForm.disinfectionDate"
                placeholder="请选择清洗消毒日期"
                value-format="yyyy-MM-dd"
                :disabled="clothesDisinfectionFormTitle !== '新增工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '修改工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '工作服、鞋清洗消毒记录详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="有效期至" prop="validPeriod">
              <el-input
                v-model="clothesDisinfectionForm.validPeriod"
                placeholder="请输入有效期至"
                clearable
                :disabled="clothesDisinfectionFormTitle !== '新增工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '修改工作服、鞋清洗消毒记录'
                  && clothesDisinfectionFormTitle !== '工作服、鞋清洗消毒记录详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <vxe-toolbar
            v-if="clothesDisinfectionFormTitle === '新增工作服、鞋清洗消毒记录'
              || clothesDisinfectionFormTitle === '修改工作服、鞋清洗消毒记录'"
          >
            <template #buttons>
              <vxe-button @click="insertRowEvent()">
                新增
              </vxe-button>
            </template>
          </vxe-toolbar>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :data="detailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column field="type" title="类别" width="200">
              <template v-slot="{row,column}">
                <vxe-radio-group v-model="row[column.property]">
                  <vxe-radio :label="1" content="服" />
                  <vxe-radio :label="2" content="鞋" />
                </vxe-radio-group>
              </template>
            </vxe-table-column>
            <vxe-table-column
              field="clothesShoeNo"
              title="工作服/鞋编码"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="cleaningTime"
              title="清洗时间"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              field="disinfectionTime"
              title="消毒时间"
              :edit-render="{name: '$input', props: {clearable: true}}"
            />
            <vxe-table-column
              v-if="clothesDisinfectionFormTitle === '新增工作服、鞋清洗消毒记录'
                || clothesDisinfectionFormTitle === '修改工作服、鞋清洗消毒记录'"
              title="操作"
              width="100"
            >
              <template #default="{ row }">
                <template>
                  <vxe-button @click="removeRowEvent(row)">
                    删除
                  </vxe-button>
                </template>
              </template>
            </vxe-table-column>
          </vxe-table>
        </el-form-item>
        <el-form-item label="复核结果" prop="reviewResult">
          <el-radio-group
            v-model="clothesDisinfectionForm.reviewResult"
            :disabled="clothesDisinfectionFormTitle !== '复核工作服、鞋清洗消毒记录'
              && clothesDisinfectionFormTitle !== '工作服、鞋清洗消毒记录详情'"
          >
            <el-radio :label="1">
              通过
            </el-radio>
            <el-radio :label="2">
              不通过
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="clothesDisinfectionDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="clothesDisinfectionFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="清洗消毒日期">
        <el-date-picker v-model="searchForm.disinfectionDate" placeholder="请选择清洗消毒日期" value-format="yyyy-MM-dd" />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['CLOTHES_DISINFECTION_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="clothesDisinfectionPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column prop="cleaningDeviceNo" label="清洗设备编号" />
      <el-table-column prop="cleaningDeviceName" label="清洗设备名称" />
      <el-table-column prop="disinfectionDeviceNo" label="消毒设备编号" />
      <el-table-column prop="disinfectionDeviceName" label="消毒设备名称" />
      <el-table-column label="清洗消毒日期">
        <template slot-scope="scope">
          <span v-if="scope.row.disinfectionDate">{{ scope.row.disinfectionDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="validPeriod" label="有效期至" />
      <el-table-column prop="operator" label="操作人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.operateDate">{{ scope.row.operateDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['CLOTHES_DISINFECTION_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['CLOTHES_DISINFECTION_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['CLOTHES_DISINFECTION_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="clothesDisinfectionPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addClothesDisinfection,
  deleteClothesDisinfection,
  updateClothesDisinfection,
  selectClothesDisinfectionInfo,
  selectClothesDisinfectionList,
  reviewClothesDisinfection
} from '@/api/produce/clothesDisinfection'
import { selectBaseInfoList } from '@/api/device/baseInfo'

export default {
  data () {
    return {
      clothesDisinfectionDialogVisible: false,
      clothesDisinfectionFormTitle: '',
      clothesDisinfectionForm: {
        id: '',
        cleaningDeviceNo: '',
        cleaningDeviceName: '',
        disinfectionDeviceNo: '',
        disinfectionDeviceName: '',
        disinfectionDate: '',
        validPeriod: '',
        status: '',
        reviewResult: '',
        clothesDetail1Json: '',
        taskId: ''
      },
      clothesDisinfectionFormRules: {
        disinfectionDate: [{ required: true, message: '清洗消毒日期不能为空', trigger: ['blur', 'change']}]
      },
      clothesDisinfectionPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        disinfectionDate: ''
      },
      devList: [],
      detailList: []
    }
  },
  created () {
    selectClothesDisinfectionList(this.searchForm).then(res => {
      this.clothesDisinfectionPage = res
    })
    selectBaseInfoList().then(res => {
      this.devList = res.list
    })
  },
  methods: {
    clothesDisinfectionDialogClose () {
      this.$refs.clothesDisinfectionFormRef.resetFields()
      this.detailList = []
    },
    clothesDisinfectionFormSubmit () {
      if (this.clothesDisinfectionFormTitle === '工作服、鞋清洗消毒记录详情') {
        this.clothesDisinfectionDialogVisible = false
        return
      }
      this.$refs.clothesDisinfectionFormRef.validate(async valid => {
        if (valid) {
          if (this.clothesDisinfectionFormTitle === '新增工作服、鞋清洗消毒记录') {
            this.clothesDisinfectionForm.id = ''
            this.clothesDisinfectionForm.status = 1
            this.clothesDisinfectionForm.clothesDetail1Json = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addClothesDisinfection(this.clothesDisinfectionForm)
          } else if (this.clothesDisinfectionFormTitle === '修改工作服、鞋清洗消毒记录') {
            this.clothesDisinfectionForm.clothesDetail1Json = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateClothesDisinfection(this.clothesDisinfectionForm)
          } else if (this.clothesDisinfectionFormTitle === '复核工作服、鞋清洗消毒记录') {
            this.clothesDisinfectionForm.status = 2
            await reviewClothesDisinfection(this.clothesDisinfectionForm)
          }
          this.clothesDisinfectionPage = await selectClothesDisinfectionList(this.searchForm)
          this.clothesDisinfectionDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.clothesDisinfectionFormTitle = '新增工作服、鞋清洗消毒记录'
      this.clothesDisinfectionDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteClothesDisinfection(row.id)
        if (this.clothesDisinfectionPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.clothesDisinfectionPage = await selectClothesDisinfectionList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.clothesDisinfectionFormTitle = '修改工作服、鞋清洗消毒记录'
      this.clothesDisinfectionDialogVisible = true
      this.selectClothesDisinfectionInfoById(row.id)
    },
    handleReview (index, row) {
      this.clothesDisinfectionFormTitle = '复核工作服、鞋清洗消毒记录'
      this.clothesDisinfectionDialogVisible = true
      this.selectClothesDisinfectionInfoById(row.id)
    },
    handleInfo (index, row) {
      this.clothesDisinfectionFormTitle = '工作服、鞋清洗消毒记录详情'
      this.clothesDisinfectionDialogVisible = true
      this.selectClothesDisinfectionInfoById(row.id)
    },
    selectClothesDisinfectionInfoById (id) {
      selectClothesDisinfectionInfo(id).then(res => {
        this.clothesDisinfectionForm.id = res.id
        this.clothesDisinfectionForm.cleaningDeviceNo = res.cleaningDeviceNo
        this.clothesDisinfectionForm.cleaningDeviceName = res.cleaningDeviceName
        this.clothesDisinfectionForm.disinfectionDeviceNo = res.disinfectionDeviceNo
        this.clothesDisinfectionForm.disinfectionDeviceName = res.disinfectionDeviceName
        this.clothesDisinfectionForm.disinfectionDate = res.disinfectionDate
        this.clothesDisinfectionForm.validPeriod = res.validPeriod
        this.clothesDisinfectionForm.status = res.status
        this.clothesDisinfectionForm.reviewResult = res.reviewResult
        this.clothesDisinfectionForm.taskId = res.taskId
        this.detailList = res.detailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectClothesDisinfectionList(this.searchForm).then(res => {
        this.clothesDisinfectionPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectClothesDisinfectionList(this.searchForm).then(res => {
        this.clothesDisinfectionPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectClothesDisinfectionList(this.searchForm).then(res => {
        this.clothesDisinfectionPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['CLOTHES_DISINFECTION_REVIEW']) && row.status === 1) {
        return 'review'
      }
      return ''
    },
    devChange1 (value) {
      if (value) {
        let dev = this.devList.find(item => item.deviceNo === value)
        this.clothesDisinfectionForm.cleaningDeviceName = dev.deviceName
      } else {
        this.clothesDisinfectionForm.cleaningDeviceName = ''
      }
    },
    devChange2 (value) {
      if (value) {
        let dev = this.devList.find(item => item.deviceNo === value)
        this.clothesDisinfectionForm.disinfectionDeviceName = dev.deviceName
      } else {
        this.clothesDisinfectionForm.disinfectionDeviceName = ''
      }
    },
    async insertRowEvent (row) {
      let { row: newRow } = await this.$refs.xTable.insertAt({}, -1)
      await this.$refs.xTable.setActiveRow(newRow)
    },
    removeRowEvent (row) {
      this.$refs.xTable.remove(row)
    }
  }
}
</script>

<style>
</style>
